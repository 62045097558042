import React from 'react';

export const MediaGallery = React.lazy(() =>
  import(/* webpackChunkName: "MediaGallery" */ './MediaGallery')
    .then((module) => ({ default: module.MediaGallery as any }))
    .catch((e) => {
      console.log('Error in MediaGallery lazy loading', e);
      return { default: () => null };
    }),
);
