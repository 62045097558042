import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { ModalProps } from '../../../../../common/components/Modal/Modal';
import { WithGroup, WithGroupProps } from '../../../contexts/Group/WithGroup';
import { Dialog } from '../../../../../common/components/Dialog/Dialog';
import { MembershipButton } from '../../Membership/MembershipButton';
import { JOIN_GROUP_DIALOG } from '../../dataHooks';

type JoinDialogComponentProps = ModalProps & WithGroupProps;

const JoinDialogComponent: React.FC<JoinDialogComponentProps> = (props) => {
  const { group, ...rest } = props;
  const { t } = useTranslation();
  const joinTitle = t('groups-web.join.title');
  const joinText = t('groups-web.join.text', {
    group: group.details!.title,
    interpolation: { escapeValue: false },
  });

  return (
    <Dialog
      title={joinTitle}
      caption={joinText}
      {...rest}
      buttons={
        <MembershipButton bw={true} biOrigin="public_post_join_group_btn" />
      }
      dataHook={JOIN_GROUP_DIALOG}
    />
  );
};

export const JoinDialog = WithGroup(
  JoinDialogComponent,
) as React.ComponentType<ModalProps>;
