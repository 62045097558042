import React from 'react';
import { ApiTypesV1QuestionAnswer } from '@wix/social-groups-api';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Divider, TPAComponentsConfig } from 'wix-ui-tpa';

import { QuestionAnswer } from './QuestionAnswer/QuestionAnswer';
import { TPAModalProps } from '../../../../../common/components/Modal/TPAModal';
import {
  ModalV2,
  ModalV2FooterProps,
} from '../../../../../common/components/Modal/ModalV2';
import { Spinner } from '../../../../../common/components/Spinner/Spinner';

interface QuestionsAnswersModalComponentProps
  extends TPAModalProps,
    ModalV2FooterProps,
    TPAComponentsConfig {
  questionsAnswers: ApiTypesV1QuestionAnswer[];
  userName: string;
}

export const QuestionsAnswersModal: React.FC<
  QuestionsAnswersModalComponentProps
> = ({
  questionsAnswers,
  mobile,
  okButton,
  cancelButton,
  sideAction,
  userName,
  ...modalProps
}) => {
  const { t } = useTranslation();

  return (
    <ModalV2 {...modalProps}>
      <ModalV2.Title>
        {t('groups-web.membership-questions.answers-modal.title', {
          userName,
        })}
      </ModalV2.Title>
      <ModalV2.Content>
        {questionsAnswers ? (
          questionsAnswers.map((qa, index) => (
            <div key={index.toString()}>
              <QuestionAnswer qa={qa} index={index + 1} />
              {index !== questionsAnswers.length - 1 ? <Divider /> : null}
            </div>
          ))
        ) : (
          <Spinner offset="L" bw={true} />
        )}
      </ModalV2.Content>
      <ModalV2.Footer
        okButton={okButton}
        cancelButton={cancelButton}
        sideAction={sideAction}
      />
    </ModalV2>
  );
};

QuestionsAnswersModal.displayName = 'QuestionsAnswersModal';
