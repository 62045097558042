import React from 'react';
import { ButtonPriority } from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { ModalProps } from '../../../../../common/components/Modal/Modal';
import { DATA_HOOKS } from './dataHooks';
import { Dialog } from '../../../../../common/components/Dialog/Dialog';
import { Button } from '../../../../../common/components/Button';

export interface WithdrawJoinRequestDialogProps extends ModalProps {
  onWithdrawJoinRequest(): void;
}

export const WithdrawJoinRequestDialog: React.FC<
  WithdrawJoinRequestDialogProps
> = (props) => {
  const { t } = useTranslation();
  const { onWithdrawJoinRequest, ...rest } = props;
  return (
    <Dialog
      title={t('groups-web.viewer.cancel-request-modal.title')}
      caption={t('groups-web.viewer.cancel-request-modal.body')}
      buttons={
        <Button
          bw={true}
          onClick={onWithdrawJoinRequest}
          priority={ButtonPriority.primary}
          data-hook={DATA_HOOKS.withdrawJoinRequestConfirmationButton}
        >
          {t('groups-web.viewer.cancel-request-modal.cta')}
        </Button>
      }
      {...rest}
    />
  );
};

WithdrawJoinRequestDialog.displayName = 'WithdrawJoinRequestDialog';
