import React from 'react';
import { Text } from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { ApiTypesV1QuestionAnswer } from '@wix/social-groups-api';

import { classes, st } from './QA.st.css';

interface QuestionAnswerProps {
  index: number;
  qa: ApiTypesV1QuestionAnswer;
}

export const QuestionAnswer: React.FC<QuestionAnswerProps> = (props) => {
  const { t } = useTranslation();
  const { qa, index } = props;

  return (
    <div className={st(classes.root)}>
      <Text className={classes.question}>
        {index}. {qa.question!.textQuestion}? {qa.question!.required ? '*' : ''}
      </Text>
      <Text className={classes.answer}>
        {qa.textAnswer || t('groups-web.membership-questions.no-response')}
      </Text>
    </div>
  );
};

QuestionAnswer.displayName = 'QuestionAnswer';
