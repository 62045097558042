import React from 'react';

import classname from 'classnames';
import { Text, TextTypography } from 'wix-ui-tpa';

import {
  ApiTypesV1GroupMemberResponse,
  canInviteMembers,
  GroupMember,
  isPendingGroup,
  MembersRequestBuilder,
} from '@wix/social-groups-api';

import { classes } from './MembersWidget.st.css';
import {
  InjectedBiLoggerProps,
  InjectedExperimentsProps,
  withBi,
  withExperiments,
  WithTranslation,
  withTranslation,
} from '@wix/yoshi-flow-editor';
import { WithGroup, WithGroupProps } from '../../../contexts/Group/WithGroup';
import {
  withSiteMembers,
  WithSiteMembers,
} from '../../../contexts/SiteMembers/withSiteMembers';
import { BIUserEntry } from '../../../../../common/bi-logger/types';
import { CanNotAddMembersModal } from '../../Modals/CanNotAddMemebersModal/CanNotAddMembersModal';
import { combineArrays } from '../../../utils/utils';
import { compose } from '../../../../../common/utils/compose';
import { groupsAddMemberClicked } from '@wix/bi-logger-groups/v2';
import { withUser } from '../../../../../common/context/user/withUser';
import { IUserContext } from '../../../../../common/context/user/IUserContext';
import { Member } from './Member';

interface MembersListState {
  isModalOpen: boolean;
}

type Props = WithTranslation &
  WithGroupProps &
  WithSiteMembers &
  InjectedBiLoggerProps &
  InjectedExperimentsProps &
  IUserContext;

const MEMBERS_QUERY_LIMIT = 5;

export class MembersListComponent extends React.Component<
  Props,
  MembersListState
> {
  static displayName = 'MembersListComponent';
  state = {
    isModalOpen: false,
  };

  componentDidMount() {
    this.setNonGroupMembers();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (!prevProps.setNonGroupMembers && this.props.setNonGroupMembers) {
      this.setNonGroupMembers();
    }
  }

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    const { group, membersUpdate } = this.props;

    const { isModalOpen } = this.state;
    const canInvite = canInviteMembers(group);
    const members = this.getMembers(canInvite);
    if (!members.length) {
      return this.renderEmptyState();
    }
    // Array.prototype.includes() is not supported in IE 🤷‍ + Set.prototype.has() has better performance
    const updating = new Set(membersUpdate);
    return (
      <>
        {members.map((m) => {
          const siteMemberId = m.getId();
          return (
            <Member
              member={m as any}
              key={siteMemberId}
              canInvite={canInvite}
              addMember={this.handleMemberAddClick}
              updating={updating.has(siteMemberId)}
            />
          );
        })}
        <CanNotAddMembersModal isOpen={isModalOpen} onClose={this.closeModal} />
      </>
    );
  }

  handleMemberAddClick = (siteMemberId: string) => {
    const { bi, group } = this.props;
    bi.report(
      groupsAddMemberClicked({
        groupId: group.groupId!,
        origin: 'widget_plus_add_btn',
        userEntry: BIUserEntry.SITE,
      }),
    );
    if (isPendingGroup(group)) {
      this.openModal();
    } else {
      this.props.addMembers(group, [siteMemberId]);
    }
  };

  private setNonGroupMembers() {
    if (this.props.setNonGroupMembers && canInviteMembers(this.props.group)) {
      const q = new MembersRequestBuilder()
        .addPaging(MEMBERS_QUERY_LIMIT)
        .build();
      this.props.setNonGroupMembers(q);
    }
  }

  private getMembers(canInvite: boolean): GroupMember[] {
    const { members, siteMembers, membersUpdate } = this.props;
    if (!members.length && !siteMembers.length) {
      return [];
    }
    const updating = new Set(membersUpdate);
    // TODO const me = new GroupMember(currentMember);
    // const iAmInGroup = me.relatedToGroup();
    // const groupMembers = iAmInGroup
    //   ? members.filter((m) => !me.isMe(m))
    //   : members;
    let otherMembers: ApiTypesV1GroupMemberResponse[] = members.slice(
      0,
      MEMBERS_QUERY_LIMIT,
    );

    if (canInvite) {
      const nonGroup = siteMembers
        .slice(0, MEMBERS_QUERY_LIMIT)
        .filter((m) => !updating.has(m.siteMemberId!));
      otherMembers = combineArrays(nonGroup, otherMembers, MEMBERS_QUERY_LIMIT);
    }
    return otherMembers.map((m) => new GroupMember(m));
  }

  private renderEmptyState() {
    return (
      <Text
        tagName="p"
        typography={TextTypography.runningText}
        className={classname(classes.description, classes.empty)}
      >
        {this.props.t('groups-web.discussion.members.empty')}
      </Text>
    );
  }
}

const enhance = compose(
  withTranslation(),
  WithGroup,
  withSiteMembers,
  withBi,
  withExperiments,
  withUser,
);

export const MembersList = enhance(MembersListComponent);
