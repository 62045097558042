import React from 'react';
import { AvatarSize, TextButtonPriority } from 'wix-ui-tpa';

import { classes } from './MembersWidget.st.css';
import { UserCard } from '../../../../../common/components/Cards/UserCard';
import { IGroupMember } from '../../../../../common/api/model/GroupMember/IGroupMember';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useUser } from '../../../../../common/context/user/useUser';
import { CustomTextButton } from 'common/components/CustomTextButton';
import { FollowButton } from '../../FollowButton/FollowButton';

interface Props {
  member: IGroupMember;
  updating?: boolean;
  canInvite: boolean;
  addMember(siteMemberId: string): void;
}

export const Member: React.FC<Props> = ({
  member,
  updating,
  canInvite,
  addMember,
}) => {
  const { t } = useTranslation();
  const { userActions } = useUser();
  const siteMemberId = member.getId();

  function getUserAction() {
    const add = canInvite && !member.relatedToGroup();
    if (add) {
      return (
        <CustomTextButton
          onClick={() => addMember(siteMemberId)}
          priority={TextButtonPriority.primary}
          disabled={updating}
        >
          {t('groups-web.add')}
        </CustomTextButton>
      );
    }
    return (
      <FollowButton
        siteMemberId={siteMemberId}
        disabled={updating}
        textButton={true}
      />
    );
  }

  return (
    <div className={classes.memberWrapper}>
      <UserCard
        className={classes.userCard}
        name={member.getName() || t('groups-web.member.anonymous')}
        pic={member.getImageUrl()}
        avatarSize={AvatarSize.small}
        onUserClick={() => userActions.openUserProfile(siteMemberId)}
        userAction={getUserAction()}
      />
    </div>
  );
};
