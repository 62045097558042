import React from 'react';
import { Text, TextButtonPriority } from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { CustomTextButton } from 'common/components/CustomTextButton';
import { LoginParams } from '../../../../../common/components/LoginView/LoginView';

import { classes } from './MembersWidget.st.css';

export const LoginText: React.FC<LoginParams> = () => {
  const { t } = useTranslation();
  return (
    <Text className={classes.description}>
      {t('groups-web.members-widget.log-in-to-view')}
    </Text>
  );
};

LoginText.displayName = 'LoginText';

export const LoginButton: React.FC<LoginParams> = ({ promptLogin }) => {
  const { t } = useTranslation();

  return (
    <CustomTextButton
      priority={TextButtonPriority.primary}
      onClick={() => promptLogin({ modal: true })}
    >
      {t('groups-web.members-widget.log-in')}
    </CustomTextButton>
  );
};
