import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Avatar, AvatarSize, Text, TextTypography } from 'wix-ui-tpa';

import {
  Dialog,
  DialogProps,
} from '../../../../../common/components/Dialog/Dialog';
import { st, classes } from './ChangeAdminRoleDialog.st.css';

export interface ChangeAdminRoleDialogProps extends DialogProps {
  name: string;
  image: string;
  isAdmin: boolean;
  onChangeAdminRole(): void;
}

const adminPermissionKeys = [
  'groups-web.group.actions.admin-role-description.posts',
  'groups-web.group.actions.admin-role-description.comments',
  'groups-web.group.actions.admin-role-description.members',
];
const translations = {
  addAdmin: {
    okLabel: 'groups-web.group.actions.add-admin-role.modal.confirm',
    title: 'groups-web.group.actions.add-admin-role.modal.title',
    description: 'groups-web.group.actions.add-admin-role.modal.description',
  },
  removeAdmin: {
    okLabel: 'groups-web.group.actions.remove-admin-role.modal.confirm',
    title: 'groups-web.group.actions.remove-admin-role.modal.title',
    description: 'groups-web.group.actions.remove-admin-role.modal.description',
  },
};

export const ChangeAdminRoleDialog: React.FC<ChangeAdminRoleDialogProps> = (
  props,
) => {
  const { t } = useTranslation();
  const { onChangeAdminRole, isOpen, name, image, onRequestClose, isAdmin } =
    props;

  const { okLabel, description, title } = isAdmin
    ? translations.removeAdmin
    : translations.addAdmin;

  return (
    <Dialog
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      cancelLabel={t('groups-web.cancel')}
      okLabel={t(okLabel)}
      onOkClick={onChangeAdminRole}
    >
      <div className={st(classes.root)}>
        <div className={classes.header}>
          <Avatar size={AvatarSize.large} src={image} name={name} />
          <Text
            typography={TextTypography.runningText}
            className={classes.memberName}
          >
            {name}
          </Text>
          <Text
            typography={TextTypography.largeTitle}
            tagName="h1"
            className={classes.title}
          >
            {t(title)}
          </Text>
        </div>
        <div>
          <Text
            typography={TextTypography.runningText}
            className={classes.description}
            tagName="div"
          >
            {t(description, { memberFullName: name })}
          </Text>

          <ul className={classes.list}>
            {adminPermissionKeys.map((translationKey) => (
              <Text
                key={translationKey}
                typography={TextTypography.runningText}
                tagName="li"
                className={classes.adminPermissionOption}
              >
                {t(translationKey)}
              </Text>
            ))}
          </ul>
        </div>
      </div>
    </Dialog>
  );
};

ChangeAdminRoleDialog.displayName = 'ChangeAdminRoleDialog';
