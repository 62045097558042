import React from 'react';
import { TextButtonPriority } from 'wix-ui-tpa';
import { TFunction } from '@wix/yoshi-flow-editor';

import { StyledDivider } from '../../../../common/components/Divider/Divider';
import { classes } from './PendingMembers.st.css';
import { Block, BlockAlign } from '../../../../common/components/Block';
import { CustomTextButton } from '../../../../common/components/CustomTextButton';

export const ShowMore: React.FC<{
  loadMore?(): void;
  t: TFunction;
}> = (props) => {
  const { loadMore, t } = props;

  return (
    <>
      <StyledDivider className={classes.divider} />
      <Block place={BlockAlign.center} autoContent>
        <CustomTextButton
          onClick={loadMore}
          priority={TextButtonPriority.primary}
        >
          {t('groups-web.showMore')}
        </CustomTextButton>
      </Block>
    </>
  );
};
