import React from 'react';
import { AvatarSize } from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { DATA_HOOKS } from '../../About/dataHooks';
import { UserCard } from '../../../../../common/components/Cards/UserCard';
import { useUser } from '../../../../../common/context/user/useUser';
import { memberWrapper } from '../../../../../common/api/model/Member';

import { classes } from './NewPostModal.st.css';

export const Author: React.FC = () => {
  const { t } = useTranslation();
  const { myMember } = useUser();
  const { nickname, photo } = memberWrapper(myMember!).profile!;

  return (
    <div className={classes.profileInfo}>
      <UserCard
        bw={true}
        name={nickname || t('groups-web.member.anonymous')}
        pic={photo?.url}
        avatarSize={AvatarSize.large}
        dataHook={DATA_HOOKS.createdBy}
      />
    </div>
  );
};

Author.displayName = 'Author';
